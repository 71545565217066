:root {
  --color-default: #364d59;
  --color-primary: #feb900;
  --color-secondary: #52565e;
}

.section-header h2 {
  font-size: 32px;
  font-weight: 700;
  position: relative;
  color: #2e3135;
  margin-bottom: 10px;
}

.services .service-item {
  padding: 20px;
  background: #f6f7f8; /* Light gray background for a more professional look */
  height: 100%;
  border-radius: 8px; /* Rounded corners for a cleaner look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.services .service-item .icon {
  width: 80px; /* Set the width of the circle */
  height: 80px; /* Set the height of the circle */
  background-color: #f0f0f0; /* Set the background color of the circle */
  border-radius: 50%; /* Make the background circular */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem; /* Space between icon and text */
  position: relative; /* Allow positioning of child elements */
  z-index: 1; /* Ensure circle background is below the icon */
}

.services .service-item .icon i {
  color: #ffb600; /* Construction tape-like yellow color */
  font-size: 2rem; /* Adjust the size of the icon */
  transition: ease-in-out 0.3s;
  z-index: 2; /* Ensure icon is in front */
  position: relative;
}

.services .service-item .icon:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: #eeeeee; /* Lighter gray background */
  border-radius: 50%;
  z-index: -1; /* Ensure the background is behind the icon */
  top: 0;
  left: 0;
  transition: 0.3s;
}

.services .service-item h3 {
  color: #23282d; /* Dark gray color for text */
  font-weight: 700;
  margin: 0 0 10px 0;
  padding-bottom: 5px;
  font-size: 18px;
  display: inline-block;
  border-bottom: 3px solid #dddddd; /* Light gray border for a subtle look */
  transition: 0.3s;
}

.services .service-item p {
  line-height: 20px;
  font-size: 14px; /* Adjusted font size for readability */
  margin-bottom: 0;
}

.services .service-item .readmore {
  margin-top: 10px;
  display: inline-block;
  color: #feb900; /* Primary color for consistency */
  text-decoration: none;
  font-weight: 500;
}

.services .service-item:hover .icon:before {
  background: #feb900; /* Primary color for hover effect */
}

.services .service-item:hover h3 {
  border-color: #feb900; /* Primary color for hover effect */
}
