/* VideoCarousel.css */

.video-carousel {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  padding-top: 60px;
  overflow: hidden;
  border-radius: 15px; /* Add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
  background-color: #fff; /* Optional: Add a background color */
}

.video-container {
  display: flex;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  position: relative;
  border-radius: 15px; /* Ensure video container also has rounded corners */
  overflow: hidden; /* Prevent overflow from rounded corners */
}

.video-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-controls {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}

.carousel-controls button {
  background-color: #ffb600;
  border: none;
  border-radius: 50%; /* Rounded buttons */
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  pointer-events: auto;
  transition: background-color 0.3s ease;
}

.carousel-controls button:hover {
  background-color: #ffd363;
}

.carousel-controls button:focus {
  outline: none;
}

.carousel-controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.prev-button {
  margin-left: 10px;
}

.next-button {
  margin-right: 10px;
}
