/********** Template CSS **********/

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 30px;
  z-index: 99;
}


/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}


/*** Button ***/
.btn {
  transition: .5s;
}

.btn.btn-primary,
.btn.btn-outline-primary:hover,
.btn.btn-secondary,
.btn.btn-outline-secondary:hover {
  color: #FFFFFF;
}

.btn.btn-primary:hover {
  background: var(--secondary);
  border-color: var(--secondary);
}

.btn.btn-secondary:hover {
  background: var(--primary);
  border-color: var(--primary);
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}


/*** Navbar ***/
.navbar.sticky-top {
  top: -100px;
  transition: .5s;
}

.navbar .navbar-brand img {
  max-height: 60px;
}

.navbar .navbar-nav .nav-link {
  margin-left: 25px;
  padding: 10px 0;
  color: #696E77;
  font-weight: 500;
  outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free" !important;
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

@media (max-width: 991.98px) {
  .navbar .navbar-brand img {
      max-height: 45px;
  }

  .navbar .navbar-nav {
      margin-top: 20px;
      margin-bottom: 15px;
  }

  .navbar .nav-item .dropdown-menu {
      padding-left: 30px;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
      display: block;
      border: none;
      margin-top: 0;
      top: 150%;
      right: 0;
      opacity: 0;
      visibility: hidden;
      transition: .5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
      top: 100%;
      visibility: visible;
      transition: .5s;
      opacity: 1;
  }
}


/*** Header ***/
.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  text-align: start !important;
  z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
  width: 15%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem !important;
  height: 3rem !important;
  background-color: var(--primary);
  border: 15px solid var(--primary);
  border-radius: 50px;
}

@media (max-width: 768px) {
  #header-carousel .carousel-item {
      position: relative;
      min-height: 650px;
  }
  
  #header-carousel .carousel-item img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
  }
}
/* 
.page-header {
  background: url(../public/img/carousel-1.jpg) center center no-repeat;
  background-size: cover;
}

.page-header .breadcrumb-item,
.page-header .breadcrumb-item a {
  font-weight: 500;
}

.page-header .breadcrumb-item a,
.page-header .breadcrumb-item+.breadcrumb-item::before {
  color: #696E77;
}

.page-header .breadcrumb-item a:hover,
.page-header .breadcrumb-item.active {
  color: var(--primary);
} */


/*** Facts ***/
@media (min-width: 992px) {
  .container.facts {
      max-width: 100% !important;
  }

  .container.facts .facts-text {
      padding-left: calc(((100% - 960px) / 2) + .75rem);
  }

  .container.facts .facts-counter {
      padding-right: calc(((100% - 960px) / 2) + .75rem);
  }
}

@media (min-width: 1200px) {
  .container.facts .facts-text  {
      padding-left: calc(((100% - 1140px) / 2) + .75rem);
  }

  .container.facts .facts-counter  {
      padding-right: calc(((100% - 1140px) / 2) + .75rem);
  }
}

@media (min-width: 1400px) {
  .container.facts .facts-text  {
      padding-left: calc(((100% - 1320px) / 2) + .75rem);
  }

  .container.facts .facts-counter  {
      padding-right: calc(((100% - 1320px) / 2) + .75rem);
  }
}

.container.facts .facts-text {
  background: linear-gradient(rgba(1, 95, 201, .9), rgba(1, 95, 201, .9)), url(../public/img/NVGrouppics/aboutImage3.jpeg) center right no-repeat;
  background-size: cover;
}

.container.facts .facts-counter {
  background: linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)), url(../public/img/NVGrouppics/aboutImage3.jpeg) center right no-repeat;
  background-size: cover;
}

.container.facts .facts-text .h-100,
.container.facts .facts-counter .h-100 {
  padding: 6rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


/*** Service ***/
.service-item {
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}

.service-item .service-icon {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-item .service-icon img {
  max-width: 60px;
  max-height: 60px;
}

.service-item a.btn {
  color: var(--primary);
}

.service-item a.btn:hover {
  color: #FFFFFF;
  background: var(--primary);
  border-color: var(--primary);
}


/*** Appointment ***/
/* .appointment {
  background: linear-gradient(rgba(1, 95, 201, .9), rgba(1, 95, 201, .9)), url(../public/img/carousel-1.jpg) center center no-repeat;
  background-size: cover;
} */


/*** Team ***/
.team-item {
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}

.team-item img {
  transition: .5s;
}

.team-item:hover img {
  transform: scale(1.1);
}

.team-text {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -50px;
  opacity: 0;
  transition: .5s;
}

.team-item:hover .team-text {
  bottom: 0;
  opacity: 1;
}

.team-item a.btn {
  color: var(--primary);
}

.team-item a.btn:hover {
  color: #FFFFFF;
  background: var(--primary);
  border-color: var(--primary);
}


/*** Testimonial ***/
/* .animated.pulse {
  animation-duration: 1.5s;
}

.testimonial-left,
.testimonial-right {
  position: relative;
}

.testimonial-left img,
.testimonial-right img {
  position: absolute;
  padding: 5px;
  border: 1px dashed var(--primary);
  border-radius: 10px;
}

.testimonial-left img:nth-child(1),
.testimonial-right img:nth-child(3) {
  width: 70px;
  height: 70px;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.testimonial-left img:nth-child(2),
.testimonial-right img:nth-child(2) {
  width: 60px;
  height: 60px;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
}

.testimonial-left img:nth-child(3),
.testimonial-right img:nth-child(1) {
  width: 50px;
  height: 50px;
  bottom: 10%;
  right: 10%;
}

.testimonial-carousel .owl-item img {
  width: 100px;
  height: 100px;
}

.testimonial-carousel .owl-nav {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  margin: 0 5px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: var(--primary);
  border-radius: 10px;
  font-size: 22px;
  transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  background: var(--secondary);
} */


/*** Footer ***/
.footer , .footer p{
  color: #A7A8B4;
}


.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: var(--light);
  font-weight: normal;
  text-transform: capitalize;
  transition: .3s;
}

.footer .btn.btn-link svg {
  color: #A7A8B4;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  color: var(--light);
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .btn.btn-square {
  color: #A7A8B4;
  border: 1px solid#A7A8B4;
}

.footer .btn.btn-square:hover {
  color: var(--secondary);
  border-color: var(--light);
}