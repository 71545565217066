.header-carousel {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh; /* Adjust as needed */
  }
  
  .header-carousel-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .header-carousel-item.active {
    opacity: 1;
  }
  
  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInLeft {
    animation-name: fadeInLeft;
  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInRight {
    animation-name: fadeInRight;
  }